import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '..';
import { populateChildren } from './toolbox';
import { queryPropTypes } from '../../lib/propTypes';

const QSiretInfoQuery = graphql`
  query QSiretInfoQuery($siret: String, $idcc: String) {
    siretInfo(siret: $siret, idcc: $idcc) {
      name
      siren
      siret
      ape
      ccns {
        ape
        title
        shortTitle
        idcc
        brochure
        from
        ccnRef
      }
    }
  }
`;

const QSiretInfo = ({ children, args, mockData }) => {
  return <Query
    query={QSiretInfoQuery}
    args={args}
    mockKey="QSiretInfo"
    mockData={mockData}
  >
    {populateChildren('siretInfo')(children)}
  </Query>;
};

QSiretInfo.propTypes = queryPropTypes;

export default QSiretInfo;
