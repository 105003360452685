import React from 'react';
import { childrenPropTypes } from '../lib/propTypes';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const defaultColumns = {
  xl: 4,
  lg: 3,
  md: 3,
  sm: 2,
  xs: 1
};

const Row = ({
  maxColumns = defaultColumns,
  children,
  className = ''
}) => {
  const getColumn = size => R.propOr(R.prop(size, defaultColumns), size, maxColumns);

  return <div className={`grid xl:grid-cols-${getColumn('xl')} lg:grid-cols-${getColumn('lg')} md:grid-cols-${getColumn('md')} sm:grid-cols-${getColumn('sm')} xs:grid-cols-${getColumn('xs')} gap-x-5 gap-y-8 my-25px ${className}`}>
    {children}
  </div>;
};

Row.propTypes = {
  children: childrenPropTypes,
  maxColumns: PropTypes.shape({
    xl: PropTypes.number,
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    xs: PropTypes.number
  }),
  className: PropTypes.string
};

export default Row;
