import {
  commitMutation, graphql
} from 'react-relay';

import environment from '..';

const mutation = graphql`
mutation IncrementMetricMutation($key: MetricKeyEnum!) {
  incrementMetric(key: $key) {
    ok
    error
  }
}`;

export default (key, done, env = environment()) => {
  const variables = { key };

  commitMutation(
    env,
    {
      mutation,
      variables,
      onCompleted: ({ incrementMetric: { ok, error } }) => {
        done(ok, error);
      },
      onError: error => {
        console.error(error);
        done(false, error);
      }
    }
  );
};
