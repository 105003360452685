import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '..';
import { populateChildren } from './toolbox';
import { queryPropTypes } from '../../lib/propTypes';

const QCcnQuery = graphql`
  query QCcnQuery($ape: String, $idcc: String, $siret: String) {
    ccn(ape: $ape, idcc: $idcc, siret: $siret) {
      ape
      title
      shortTitle
      idcc
      brochure
      ccnRef
    }
  }
`;

const QCcn = ({ children, args, mockData }) => {
  return <Query
    query={QCcnQuery}
    args={args}
    mockKey="QCcn"
    mockData={mockData}
  >
    {populateChildren('ccn')(children)}
  </Query>;
};

QCcn.propTypes = queryPropTypes;

export default QCcn;
