import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import QCcn from '../../../_graphql/queries/QCcn';
import LabelValue from '../../../components/LabelValue';
import LabelInput from '../../../components/LabelInput';
import Select from '../../../components/Select';
import Alert from '../../../components/Alert';
import Row from '../../../components/Row';

const getCcnLabel = from => R.propOr('La convention collective', from, {
  ape: 'La convention collective rapprochée par le code APE',
  dsn: 'La convention collective déclarée dans la DSN'
});

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

const Ccn = ({ oavIdcc, ccns, onChange, gamme }) => {
  const [selectedCcn, setSelectedCcn] = useState(isNilOrEmpty(ccns) ? {} : R.nth(0, ccns));
  const [ccnFrom, setCcnFrom] = useState(isNilOrEmpty(selectedCcn) ? undefined : selectedCcn.from);
  const [idcc, setIdcc] = useState('');

  useEffect(() => {
    setSelectedCcn(isNilOrEmpty(ccns) ? {} : R.nth(0, ccns));
  }, [ccns]);

  useEffect(() => {
    if (!R.isNil(selectedCcn) && R.includes(selectedCcn.from, ['ape', 'dsn'])) {
      setCcnFrom(selectedCcn.from);
    }
  }, [selectedCcn]);

  const onSelectCcn = ccn => {
    onChange(ccn.idcc, ccn.ccnRef);
    setSelectedCcn(ccn);
  };

  const idccsAreDifferent = !isNilOrEmpty(oavIdcc) && !isNilOrEmpty(selectedCcn) && !R.equals(oavIdcc, selectedCcn.idcc);

  return <>
    {!R.either(R.isNil, R.isEmpty)(oavIdcc) && <QCcn args={{ idcc: oavIdcc }}>
      {data => {
        if (R.either(R.isNil, R.isEmpty)(data)) {
          return null;
        }

        const { shortTitle } = R.compose(
          R.when(R.isNil, R.always({})),
          R.nth(0)
        )(data);

        return <Row maxColumns={{ xl: 2, lg: 2 }}>
          <LabelValue
            label="La convention collective de l'OAV"
            value={oavIdcc}
          />

          <LabelValue
            label=""
            value={shortTitle}
          />
        </Row>;
      }}
    </QCcn>}

    <Row maxColumns={{ xl: 2, lg: 2 }}>
      <LabelInput
        label={getCcnLabel(ccnFrom)}
        value={selectedCcn.idcc || idcc}
        width={160}
        onChange={v => {
          setIdcc(v);
          onChange(v);
        }}
        placeholder="Saisir un IDCC..."
        isValid={R.complement(R.either(R.isNil, R.isEmpty))}
      />

      {R.length(ccns) > 1
        ? <Select
          onChange={onSelectCcn}
          optionKey="shortTitle"
          labelKey="formattedTitle"
          options={R.map(ccn => {
            const { idcc, shortTitle } = ccn;
            return R.assoc('formattedTitle', `${idcc} - ${shortTitle}`)(ccn);
          })(ccns)}
          style={{
            maxWidth: '800px'
          }}
        />
        : <LabelValue
          label=""
          value={selectedCcn.shortTitle}
        />
      }
    </Row>

    {idccsAreDifferent && (
      <Alert
        message={<span>
          Le code IDCC {ccnFrom === 'ape' ? 'rapproché par le code APE' : 'déclaré dans la DSN'} est différent de celui que vous avez saisi dans l’outil des assurances collectives.
          <br/>
          Veuillez vérifier avec votre client que le code IDCC saisi correspond à celui repris sur les bulletins de salaire des salariés.
        </span>}
        type="warning"
      />
    )}

    <Row>
      <LabelValue
        label="Code brochure"
        value={selectedCcn.brochure}
      />
    </Row>
  </>;
};

Ccn.propTypes = {
  oavIdcc: PropTypes.string,
  ccns: PropTypes.arrayOf(
    PropTypes.shape({
      idcc: PropTypes.string,
      shortTitle: PropTypes.string,
      brochure: PropTypes.string,
      from: PropTypes.string
    })
  ),
  onChange: PropTypes.func.isRequired
};

export default Ccn;
