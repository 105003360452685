import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import QComparisons from '../../../_graphql/queries/QComparisons';
import * as R from 'ramda';
import useIdccSentence from '../../../hooks/useIdccSentence';
import Title from '../../../components/Title';
import Tabs from '../../../components/Tabs';
import ComparisonNotes from './comparisons/ComparisonNotes';
import Comparison from './comparisons/Comparison';
import GeneralInformations from './GeneralInformations';
import QRawOfferByIdcc from '../../../_graphql/queries/QRawOfferByIdcc';
import Prices from './comparisons/Prices';

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

const ComparisonsResult = ({ idcc, ccnRef, gamme, college, showGeneralInformations, showOnlyRefOffer = false }) => {
  return <QComparisons args={{ idcc, ccnRef, gamme, college }}>
    {comparisons => {
      if (R.either(R.isNil, R.isEmpty)(comparisons) || R.either(R.isNil, R.isEmpty)(R.path([0, 'rawOffers'], comparisons))) {
        return <span>Aucune offre trouvée.</span>;
      }

      return <>
        <Title banner>
          {'Analyse de la conformité des garanties :'}
        </Title>

        {R.length(comparisons) === 1 && <Comparison
          comparison={R.nth(0, comparisons)}
          gamme={gamme}
          college={college}
          showGeneralInformations={showGeneralInformations}
          showOnlyRefOffer={showOnlyRefOffer}
          idcc={idcc}
        />}

        {R.length(comparisons) === 2 && <Tabs
          options={[
            {
              key: 'pack',
              label: 'Pack',
              children: <Comparison
                comparison={R.find(R.propEq('comparisonType', 'pack'))(comparisons)}
                gamme={gamme}
                college={college}
                showGeneralInformations={showGeneralInformations}
                showOnlyRefOffer={showOnlyRefOffer}
                idcc={idcc}
              />
            },
            {
              key: 'modulr',
              label: 'À la carte',
              children: <Comparison
                comparison={R.find(R.propEq('comparisonType', 'modular'))(comparisons)}
                gamme={gamme}
                college={college}
                showGeneralInformations={showGeneralInformations}
                showOnlyRefOffer={showOnlyRefOffer}
                idcc={idcc}
              />
            }
          ]}
        />}

      </>;
    }}
  </QComparisons>;
};

const Comparisons = ({
  idcc,
  gamme,
  ccnRef,
  college
}) => {
  const [idccSentence, refetch] = useIdccSentence(idcc, gamme, college, ccnRef);

  useEffect(() => {
    if (!R.isNil(idcc) && !R.isNil(gamme) && !R.isNil(gamme)) {
      refetch();
    }
  }, [idcc, gamme, college]);

  if (idccSentence === 'loading') { // still loading
    return null;
  }

  if (!isNilOrEmpty(idccSentence) && !isNilOrEmpty(idccSentence.sentence)) {
    const {
      showGeneralInformations,
      showComparison,
      showFullComparison
    } = R.applySpec({
      showGeneralInformations: R.propEq('showGeneralInformations', true),
      showComparison: R.propEq('showComparison', true),
      showFullComparison: R.propEq('showFullComparison', true)
    })(idccSentence);

    return <>
      <ComparisonNotes notes={R.compose(
        R.map(note => <React.Fragment key={note}>{note} <br/></React.Fragment>),
        R.split('\\n'),
        R.defaultTo('')
      )(idccSentence.sentence)}/>

      {showGeneralInformations && !showComparison && !showFullComparison && <>
        <QRawOfferByIdcc args={{ idcc, college, gamme, ccnRef }}>
          {data => {
            if (R.either(R.isNil, R.isEmpty)(data)) {
              return <span>Aucune offre trouvée.</span>;
            }

            return <>
              <Title banner>
                {'Analyse de la conformité des garanties :'}
              </Title>

              <Prices
                prices={R.propOr([], 'prices', data)}
                beneficiaries={R.pathOr('', ['generalInformation', 'beneficiaries'], data)}
                college={college}
                gamme={gamme}
                idcc={idcc}
              />

              <GeneralInformations rawOffer={data}/>
            </>;
          }}
        </QRawOfferByIdcc>
      </>}

      {(showComparison || showFullComparison) && <ComparisonsResult
        showGeneralInformations={showGeneralInformations}
        showOnlyRefOffer={showComparison}
        idcc={idcc}
        gamme={gamme}
        college={college}
        ccnRef={ccnRef}
      />}
    </>;
  }

  return <ComparisonsResult
    showGeneralInformations
    idcc={idcc}
    gamme={gamme}
    college={college}
    ccnRef={ccnRef}
    showOnlyRefOffer={false}
  />;
};

ComparisonsResult.propTypes = {
  idcc: PropTypes.string.isRequired,
  ccnRef: PropTypes.string,
  gamme: PropTypes.oneOf(['sante', 'prev']),
  college: PropTypes.oneOf(['C', 'NC', 'TOUS']),
  showGeneralInformations: PropTypes.bool.isRequired,
  showOnlyRefOffer: PropTypes.bool.isRequired
};

Comparisons.propTypes = {
  idcc: PropTypes.string.isRequired,
  ccnRef: PropTypes.string,
  gamme: PropTypes.oneOf(['sante', 'prev']),
  college: PropTypes.oneOf(['C', 'NC', 'TOUS'])
};

export default Comparisons;
