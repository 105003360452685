import React from 'react';
import PropTypes from 'prop-types';
import { childrenPropTypes } from '../../../../lib/propTypes';

const ComparisonNotes = ({ notes }) => {
  return <div className="text-justify text-lg py-10px px-25px border-2 border-primary rounded-lg mt-15px mx-auto w-11/12 bg-lightblue">
    {notes}
  </div>;
};

ComparisonNotes.propTypes = {
  notes: PropTypes.arrayOf(childrenPropTypes).isRequired
};

export default ComparisonNotes;
