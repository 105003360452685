import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({
  label,
  size = 15,
  onChange
}) => {
  return <div className="inline-flex">
    <input
      type="checkbox"
      className="text-sm border border-gray-400 rounded-sm outline-none appearance-none pt-2px mt-5px checked:bg-primary checked:border-gray-900"
      id={label}
      style={{
        height: size,
        width: size,
        minWidth: size,
        minHeight: size,
        marginRight: '5px'
      }}
      onChange={e => onChange(e.target.checked)}
    />
    <label htmlFor={label}>{label}</label>
  </div>;
};

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default Checkbox;
