import React from 'react';
import { graphql } from 'react-relay';
import { Query } from './_graphql';
import { childrenPropTypes } from './lib/propTypes';
const R = require('ramda');

const DataContext = React.createContext();

const withDataQuery = graphql`
  query withDataQuery {
    garantyRefs {
      ref
      garantyGamme
      garantyGroup
      garantyGroupLabel
      label
      linkedRefs
    }
  }
`;

const DataProvider = ({
  children
}) => <Query
  query={withDataQuery}
  caching
>
  {({ garantyRefs }) => {
    garantyRefs = R.defaultTo([])(garantyRefs);

    const refsOrder = [
      'P7',
      'P6',
      'P5',
      'P42'
    ];

    const sortedRefs = R.compose(
      R.uniq,
      R.concat(refsOrder),
      R.pluck('ref')
    )(garantyRefs);

    garantyRefs = R.compose(
      R.reject(R.isNil),
      R.map(ref => {
        return R.find(R.propEq('ref', ref))(garantyRefs);
      })
    )(sortedRefs);

    return <DataContext.Provider value={{ garantyRefs }}>
      {children}
    </DataContext.Provider>;
  }}
</Query>;

export default function withData (Component) {
  return function DataComponent (props) {
    return <DataContext.Consumer>
      {({ garantyRefs }) => {
        const getGarantyRefsByGamme = gamme => R.filter(R.propEq('garantyGamme', gamme))(garantyRefs);

        return <Component
          {...props}
          garantyRefs={garantyRefs}
          getGarantyRefsByGamme={getGarantyRefsByGamme}
        />;
      }}
    </DataContext.Consumer>;
  };
}

DataProvider.propTypes = {
  children: childrenPropTypes
};

export {
  DataProvider
};
