/**
 * @flow
 * @relayHash 3a297d9174fcf94168f0dbf8f378c9fc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QRawOfferQueryVariables = {|
  rawOfferId: string
|};
export type QRawOfferQueryResponse = {|
  +rawOffer: ?{|
    +id: ?string,
    +rawOfferName: ?string,
    +versions: ?$ReadOnlyArray<?{|
      +index: ?number,
      +options: ?$ReadOnlyArray<?{|
        +id: ?string,
        +name: ?string,
        +items: ?$ReadOnlyArray<?{|
          +classification: ?{|
            +ref: ?string,
            +label: ?string,
          |},
          +value: ?string,
        |}>,
      |}>,
    |}>,
    +generalInformation: ?{|
      +beneficiaries: ?string,
      +contributionDistribution: ?string,
      +extensionOrderDate: ?string,
      +amendmentsDate: ?string,
      +agreementDate: ?string,
      +extensionAmendmentsDate: ?string,
      +highDegreeOfSolidarity: ?string,
      +maintenanceOfGuaranteesOutsidePortability: ?string,
    |},
    +prices: ?$ReadOnlyArray<?{|
      +index: ?number,
      +prices: ?$ReadOnlyArray<?{|
        +label: ?string,
        +value: ?string,
      |}>,
    |}>,
  |}
|};
export type QRawOfferQuery = {|
  variables: QRawOfferQueryVariables,
  response: QRawOfferQueryResponse,
|};
*/


/*
query QRawOfferQuery(
  $rawOfferId: String!
) {
  rawOffer(rawOfferId: $rawOfferId) {
    id
    rawOfferName
    versions {
      index
      options {
        id
        name
        items {
          classification {
            ref
            label
          }
          value
        }
      }
    }
    generalInformation {
      beneficiaries
      contributionDistribution
      extensionOrderDate
      amendmentsDate
      agreementDate
      extensionAmendmentsDate
      highDegreeOfSolidarity
      maintenanceOfGuaranteesOutsidePortability
    }
    prices {
      index
      prices {
        label
        value
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rawOfferId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "rawOfferId",
        "variableName": "rawOfferId"
      }
    ],
    "concreteType": "RawOffer",
    "kind": "LinkedField",
    "name": "rawOffer",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawOfferName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RawOfferVersion",
        "kind": "LinkedField",
        "name": "versions",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RawOfferOption",
            "kind": "LinkedField",
            "name": "options",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RawOfferItem",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RawOfferItemClassification",
                    "kind": "LinkedField",
                    "name": "classification",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ref",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RawOfferGeneralInformation",
        "kind": "LinkedField",
        "name": "generalInformation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "beneficiaries",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contributionDistribution",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extensionOrderDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amendmentsDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "agreementDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extensionAmendmentsDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "highDegreeOfSolidarity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maintenanceOfGuaranteesOutsidePortability",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Prices",
        "kind": "LinkedField",
        "name": "prices",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Price",
            "kind": "LinkedField",
            "name": "prices",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QRawOfferQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QRawOfferQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "id": "3a297d9174fcf94168f0dbf8f378c9fc",
    "metadata": {},
    "name": "QRawOfferQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6883b66c59969869edfc8feacf3c9b5b';

module.exports = node;
