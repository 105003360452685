import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '..';
import { populateChildren } from './toolbox';
import { queryPropTypes } from '../../lib/propTypes';

const QRawOfferByIdccQuery = graphql`
  query QRawOfferByIdccQuery($idcc: String!, $college: CollegeEnum!, $gamme: GammeEnum!, $ccnRef: String) {
    rawOfferByIdcc(idcc: $idcc, college: $college, gamme: $gamme, ccnRef: $ccnRef) {
      id
      rawOfferName
      versions {
        index
        options {
          id
          name
          items {
            classification {
              ref
              label
            }
            value
          }
        }
      }
      generalInformation {
        beneficiaries
        contributionDistribution
        extensionOrderDate
        amendmentsDate
        agreementDate
        extensionAmendmentsDate
        highDegreeOfSolidarity
        maintenanceOfGuaranteesOutsidePortability
      }
      prices {
        index
        prices {
          label
          value
        }
      }
    }
  }
`;

const QRawOfferByIdcc = ({ children, args, mockData }) => {
  return <Query
    query={QRawOfferByIdccQuery}
    args={args}
    mockKey="QRawOfferByIdcc"
    mockData={mockData}
  >
    {populateChildren('rawOfferByIdcc')(children)}
  </Query>;
};

QRawOfferByIdcc.propTypes = queryPropTypes;

export default QRawOfferByIdcc;
