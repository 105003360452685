import { compose, equals, is, when } from 'ramda';

const idccIs = (idcc, sourceIdcc) => compose(
  equals(sourceIdcc),
  when(is(String), parseInt)
)(idcc);

const IDCCS = {
  METALLURGIE: 3248,
  METALLURGIE_ISERE_HAUTES_ALPES: 2221
};

export {
  idccIs,
  IDCCS
};
