import React from 'react';
const R = require('ramda');

const populateChildren = (dataKey) => (children, childDataProp) => (data) => {
  if (R.isNil(children)) return null;

  if (!R.isNil(dataKey)) {
    data = R.prop(dataKey, data);
  }

  if (R.is(Function, children)) return children(data);
  return React.cloneElement(
    children,
    {
      [R.when(R.isNil, R.always(dataKey), childDataProp)]: data
    }
  );
};

export {
  populateChildren
};
