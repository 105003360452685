/**
 * @flow
 * @relayHash 57221aa1da9f01f76c3fa1a82d6419f5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EnvEnum = "DISABLE_MATCHING" | "SERVICE_ERNEST_URL" | "%future added value";
export type useEnvQueryVariables = {|
  envVar: EnvEnum
|};
export type useEnvQueryResponse = {|
  +env: ?string
|};
export type useEnvQuery = {|
  variables: useEnvQueryVariables,
  response: useEnvQueryResponse,
|};
*/


/*
query useEnvQuery(
  $envVar: EnvEnum!
) {
  env(envVar: $envVar)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "envVar"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "envVar",
        "variableName": "envVar"
      }
    ],
    "kind": "ScalarField",
    "name": "env",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useEnvQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useEnvQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "57221aa1da9f01f76c3fa1a82d6419f5",
    "metadata": {},
    "name": "useEnvQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e4b65e1dccd266696d4222e058d7b5ca';

module.exports = node;
