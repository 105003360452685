import React from 'react';
import * as R from 'ramda';
import QMatching from '../../_graphql/queries/QMatching';
import useMetric from '../../hooks/useMetric';

const Matching = () => {
  useMetric('ACCESS_PAGE_MATCHING');

  return <QMatching>
    {matchingIframeUrl => {
      if (R.isNil(matchingIframeUrl)) {
        window.location = '/';
      }

      return <iframe
        style={{
          width: '100%',
          height: '99.2vh',
          border: 'none'
        }}
        src={matchingIframeUrl}
      />;
    }}
  </QMatching>;
};

export default Matching;
