import React from 'react';
import PropTypes from 'prop-types';
import { childrenPropTypes } from '../lib/propTypes';

const Title = ({
  children,
  small = false,
  banner = false,
  className = ''
}) => {
  if (small) {
    return <div className={`mt-25px font-bold ${className}`}>
      {children}
    </div>;
  }

  if (banner) {
    return <div className={`bg-primary text-white text-xl font-bold text-center h-55px leading-55px w-full mt-20px mb-35px ${className}`}>
      {children}
    </div>;
  }

  return <div className={`bg-primary w-fit text-white text-xl font-bold uppercase py-15px px-30px ${className}`}>
    {children}
  </div>;
};

Title.propTypes = {
  children: childrenPropTypes,
  small: PropTypes.bool,
  banner: PropTypes.bool,
  className: PropTypes.string
};

export default Title;
