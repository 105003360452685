import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import useKeyPressEnter from '../hooks/useKeyPressEnter';

const useEdit = ({ onChange, value, isValid }) => {
  const $isValid = value => {
    if (R.is(Function, isValid)) {
      return isValid(value);
    }
    return true;
  };

  const [state, setState] = useState({
    editing: R.either(R.isNil, R.isEmpty)(value),
    value,
    error: false,
    isFocused: false
  });

  const $onChange = e => {
    setState(R.compose(
      R.assoc('error', false),
      R.assoc('value', e.target.value)
    ));
  };

  const onValidate = () => {
    if ($isValid(state.value)) {
      onChange(state.value);
      setState(R.assoc('editing', false));
    } else {
      setState(R.assoc('error', true));
    }
  };

  useKeyPressEnter(onValidate, state.editing && state.isFocused, state);

  return {
    state,
    $onChange,
    onValidate,
    setState
  };
};

const classNames = {
  block: 'inline-block color-primary md:ml-20px xs:ml-5px border border-solid border-gray-400 rounded-md text-primary',
  img: 'md:h-20px sm:h-15px xs:h-10px float-right cursor-pointer'
};

const LabelInput = ({
  label,
  value = '',
  width = 200,
  onChange,
  placeholder = '',
  isValid,
  errorMessage = 'La donnée saisie est invalide.',
  formatting = v => v,
  readOnly = false
}) => {
  const {
    state,
    $onChange,
    onValidate,
    setState
  } = useEdit({ onChange, value, isValid });

  return <div>
    <div className="py-5px inline-block">{label}</div>
    {(state.editing && !readOnly)
      ? (
        <div className={`pr-5px h-40px ${classNames.block}`}>
          <input
            style={{ width: width - 11 }}
            value={state.value}
            onChange={$onChange}
            placeholder={placeholder}
            className="py-5px pl-5px text-primary lg:text-xl md:text-lg xs:text-sm w-full border-none bg-none bg-transparent"
            onFocus={() => setState(R.assoc('isFocused', true))}
            onBlur={() => setState(R.assoc('isFocused', false))}
          />

          <img
            src="/image/check.svg"
            onClick={onValidate}
            className={`mt-8px ${classNames.img}`}
          />
        </div>
      )
      : (
        <div
          className={`py-5px px-10px ${classNames.block}`}
          style={{ minWidth: width }}
        >
          {formatting(value)}

          {!readOnly && <img
            src="/image/edit.svg"
            onClick={() => setState(R.assoc('editing', true))}
            className={`mt-5px ml-5px ${classNames.img}`}
          />}
        </div>
      )
    }

    {state.error && errorMessage}
  </div>;
};

LabelInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  width: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isValid: PropTypes.func,
  errorMessage: PropTypes.string,
  formatting: PropTypes.func,
  readOnly: PropTypes.bool
};

export default LabelInput;
