/**
 * @flow
 * @relayHash 9a83a88e3f4a42c55ca7e8c80c666b1c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CollegeEnum = "C" | "NC" | "TOUS" | "%future added value";
export type GammeEnum = "prev" | "sante" | "%future added value";
export type useIdccSentenceQueryVariables = {|
  idcc: string,
  gamme: GammeEnum,
  college: CollegeEnum,
  ccnRef?: ?string,
|};
export type useIdccSentenceQueryResponse = {|
  +idccSentence: ?{|
    +sentence: ?string,
    +showGeneralInformations: ?boolean,
    +showComparison: ?boolean,
    +showFullComparison: ?boolean,
  |}
|};
export type useIdccSentenceQuery = {|
  variables: useIdccSentenceQueryVariables,
  response: useIdccSentenceQueryResponse,
|};
*/


/*
query useIdccSentenceQuery(
  $idcc: String!
  $gamme: GammeEnum!
  $college: CollegeEnum!
  $ccnRef: String
) {
  idccSentence(idcc: $idcc, gamme: $gamme, college: $college, ccnRef: $ccnRef) {
    sentence
    showGeneralInformations
    showComparison
    showFullComparison
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ccnRef"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "college"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gamme"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "idcc"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ccnRef",
        "variableName": "ccnRef"
      },
      {
        "kind": "Variable",
        "name": "college",
        "variableName": "college"
      },
      {
        "kind": "Variable",
        "name": "gamme",
        "variableName": "gamme"
      },
      {
        "kind": "Variable",
        "name": "idcc",
        "variableName": "idcc"
      }
    ],
    "concreteType": "IdccSentence",
    "kind": "LinkedField",
    "name": "idccSentence",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sentence",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "showGeneralInformations",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "showComparison",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "showFullComparison",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useIdccSentenceQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useIdccSentenceQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": "9a83a88e3f4a42c55ca7e8c80c666b1c",
    "metadata": {},
    "name": "useIdccSentenceQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd87a2634cb15e9f29a2c66f4e8615548';

module.exports = node;
