import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { childrenPropTypes } from '../lib/propTypes';

const Alert = ({
  type,
  message,
  style = {}
}) => {
  const { color, image } = R.prop(type, {
    warning: { color: 'orange', image: 'alert.svg' }
  });

  return <span style={{ color, ...style }}>
    <img
      src={`/image/${image}`}
      className="inline-block"
      style={{ height: '23px', marginRight: '5px' }}
    />
    {message}
  </span>;
};

Alert.propTypes = {
  type: PropTypes.oneOf(['warning']),
  message: childrenPropTypes,
  style: PropTypes.object
};

export default Alert;
