/**
 * @flow
 * @relayHash 03ed92d67e2da27c61f9dbee4452d321
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CollegeEnum = "C" | "NC" | "TOUS" | "%future added value";
export type GammeEnum = "prev" | "sante" | "%future added value";
export type QRawOfferByIdccQueryVariables = {|
  idcc: string,
  college: CollegeEnum,
  gamme: GammeEnum,
  ccnRef?: ?string,
|};
export type QRawOfferByIdccQueryResponse = {|
  +rawOfferByIdcc: ?{|
    +id: ?string,
    +rawOfferName: ?string,
    +versions: ?$ReadOnlyArray<?{|
      +index: ?number,
      +options: ?$ReadOnlyArray<?{|
        +id: ?string,
        +name: ?string,
        +items: ?$ReadOnlyArray<?{|
          +classification: ?{|
            +ref: ?string,
            +label: ?string,
          |},
          +value: ?string,
        |}>,
      |}>,
    |}>,
    +generalInformation: ?{|
      +beneficiaries: ?string,
      +contributionDistribution: ?string,
      +extensionOrderDate: ?string,
      +amendmentsDate: ?string,
      +agreementDate: ?string,
      +extensionAmendmentsDate: ?string,
      +highDegreeOfSolidarity: ?string,
      +maintenanceOfGuaranteesOutsidePortability: ?string,
    |},
    +prices: ?$ReadOnlyArray<?{|
      +index: ?number,
      +prices: ?$ReadOnlyArray<?{|
        +label: ?string,
        +value: ?string,
      |}>,
    |}>,
  |}
|};
export type QRawOfferByIdccQuery = {|
  variables: QRawOfferByIdccQueryVariables,
  response: QRawOfferByIdccQueryResponse,
|};
*/


/*
query QRawOfferByIdccQuery(
  $idcc: String!
  $college: CollegeEnum!
  $gamme: GammeEnum!
  $ccnRef: String
) {
  rawOfferByIdcc(idcc: $idcc, college: $college, gamme: $gamme, ccnRef: $ccnRef) {
    id
    rawOfferName
    versions {
      index
      options {
        id
        name
        items {
          classification {
            ref
            label
          }
          value
        }
      }
    }
    generalInformation {
      beneficiaries
      contributionDistribution
      extensionOrderDate
      amendmentsDate
      agreementDate
      extensionAmendmentsDate
      highDegreeOfSolidarity
      maintenanceOfGuaranteesOutsidePortability
    }
    prices {
      index
      prices {
        label
        value
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ccnRef"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "college"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gamme"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "idcc"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ccnRef",
        "variableName": "ccnRef"
      },
      {
        "kind": "Variable",
        "name": "college",
        "variableName": "college"
      },
      {
        "kind": "Variable",
        "name": "gamme",
        "variableName": "gamme"
      },
      {
        "kind": "Variable",
        "name": "idcc",
        "variableName": "idcc"
      }
    ],
    "concreteType": "RawOffer",
    "kind": "LinkedField",
    "name": "rawOfferByIdcc",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawOfferName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RawOfferVersion",
        "kind": "LinkedField",
        "name": "versions",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RawOfferOption",
            "kind": "LinkedField",
            "name": "options",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RawOfferItem",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RawOfferItemClassification",
                    "kind": "LinkedField",
                    "name": "classification",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ref",
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RawOfferGeneralInformation",
        "kind": "LinkedField",
        "name": "generalInformation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "beneficiaries",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contributionDistribution",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extensionOrderDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amendmentsDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "agreementDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extensionAmendmentsDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "highDegreeOfSolidarity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maintenanceOfGuaranteesOutsidePortability",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Prices",
        "kind": "LinkedField",
        "name": "prices",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Price",
            "kind": "LinkedField",
            "name": "prices",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QRawOfferByIdccQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QRawOfferByIdccQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "id": "03ed92d67e2da27c61f9dbee4452d321",
    "metadata": {},
    "name": "QRawOfferByIdccQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9720dddb1596fc682cae814b6be85f4f';

module.exports = node;
