import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const LabelValue = ({
  label,
  value,
  style = {},
  className = ''
}) => {
  if (R.either(R.isNil, R.isEmpty)(value)) {
    return null;
  }

  return <div className={className}>
    <div className="inline-block">{label}</div>
    <div
      className="inline-block border border-gray-400 rounded-md py-5px px-15px text-primary md:ml-20px xs:ml-5px"
      style={style}
    >
      {value}
    </div>
  </div>;
};

LabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string
};

export default LabelValue;
