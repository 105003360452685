import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '..';
import { populateChildren } from './toolbox';
import { queryPropTypes } from '../../lib/propTypes';

const QComparisonQuery = graphql`
  query QComparisonsQuery($idcc: String!, $gamme: GammeEnum!, $ccnRef: String, $college: CollegeEnum) {
    comparisons(idcc: $idcc, gamme: $gamme, ccnRef: $ccnRef, college: $college) {
      rawOffers {
        rawOfferId
        rawOfferVersion
        rawOfferOptionId
        rawOfferOptionName
        selected
      }
      notes
      comparisonType
      comparisons {
        group
        refs {
          rawOfferItemId
          ref
          label
          value
          computedValue {
            value
            unit
            base
          }
          rawOffersItem {
            value
            computedValue {
              value
              unit
              base
            }
            comparison {
              result
            }
          }
        }
      }
    }
  }
`;

const QComparisons = ({ children, args, mockData }) => {
  return <Query
    query={QComparisonQuery}
    args={args}
    mockKey="QComparison"
    mockData={mockData}
  >
    {populateChildren('comparisons')(children)}
  </Query>;
};

QComparisons.propTypes = queryPropTypes;

export default QComparisons;
