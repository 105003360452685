import React, { useState } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { childrenPropTypes } from '../lib/propTypes';

const useTabs = options => {
  const [selected, setSelected] = useState(R.path([0, 'key'], options));

  const renderTab = () => {
    if (R.isNil(selected)) {
      return null;
    }

    return R.compose(
      R.propOr(<span>No children to render</span>, 'children'),
      R.when(R.isNil, R.always({})),
      R.find(R.propEq('key', selected))
    )(options);
  };

  const getTabClassName = key => {
    let className = 'py-5px px-15px inline-block';

    if (key === selected) {
      className = `${className} bg-primary text-white`;
    } else {
      className = `${className} bg-white text-primary`;
    }

    return className;
  };

  return {
    setSelected,
    renderTab,
    getTabClassName
  };
};

const Tabs = ({
  options
}) => {
  const {
    setSelected,
    renderTab,
    getTabClassName
  } = useTabs(options);

  return <>
    <div className="mx-auto text-xl font-bold border rounded-sm border-primary cursor-pointer w-fit">
      {R.map(({ key, label }) => {
        return <div
          key={key}
          onClick={() => setSelected(key)}
          className={getTabClassName(key)}
        >
          {label}
        </div>;
      })(options)}
    </div>

    {renderTab()}
  </>;
};

Tabs.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      children: childrenPropTypes
    })
  )
};

export default Tabs;
