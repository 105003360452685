import './styles/style.css';
import './styles/loading.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Tokens from './lib/Tokens';
import GQLEnvironment from './_graphql/Environment';

const getDocumentRoot = () => {
  const element = document.createElement('div');
  document.body.appendChild(element);
  return element;
};

Tokens.setKey(process.env.API_USER_ID, process.env.API_KEY);

(async () => {
  await GQLEnvironment.init();
  ReactDOM.render(
    <App/>,
    getDocumentRoot()
  );
})();
