import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

const Select = ({
  options,
  optionKey,
  labelKey,
  onChange,
  style = {},
  noValueLabel = 'Sélectionnez une valeur dans la liste'
}) => {
  const $onChange = e => {
    if (!R.isNil(onChange) && R.is(Function, onChange)) {
      const parsedValue = JSON.parse(e.target.value);
      if (!R.propEq(optionKey, noValueLabel, parsedValue)) {
        onChange(parsedValue);
      }
    }
  };

  return <select
    onChange={$onChange}
    style={style}
    className="text-primary lg:text-xl md:text-lg xs:text-sm rounded-md h-40px border border-gray-400"
  >
    {R.compose(
      R.map(option => {
        return <option
          key={R.prop(optionKey, option)}
          value={JSON.stringify(option)}
        >
          {R.propOr('', labelKey, option)}
        </option>;
      }),
      R.prepend({ [labelKey]: noValueLabel, [optionKey]: noValueLabel })
    )(options)}
  </select>;
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  optionKey: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  noValueLabel: PropTypes.string,
  style: PropTypes.object
};

export default Select;
