import { graphql } from 'react-relay';
import useFetchQuery from './useFetchQuery';
import { useEffect } from 'react';

const QIdccSentenceQuery = graphql`
  query useIdccSentenceQuery($idcc: String!, $gamme: GammeEnum!, $college: CollegeEnum!, $ccnRef: String) {
    idccSentence(idcc: $idcc, gamme: $gamme, college: $college, ccnRef: $ccnRef) {
      sentence
      showGeneralInformations
      showComparison
      showFullComparison
    }
  }
`;

const useIdccSentence = (idcc, gamme, college, ccnRef) => {
  const [response, refetch] = useFetchQuery({
    query: QIdccSentenceQuery,
    dataProp: 'idccSentence',
    args: { idcc, gamme, college, ccnRef },
    defaultData: 'loading'
  });

  useEffect(() => {
    refetch();
  }, [ccnRef]);

  return [response, refetch];
};

export default useIdccSentence;
