import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import * as R from 'ramda';
import Offer from './offer/Offer';
import { getCollege, getGamme } from '../data';
import siret from 'siret';
import useMetric from '../hooks/useMetric';

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

const useQuery = () => {
  const search = new URLSearchParams(useLocation().search);
  const getFromQuery = (k, or = '') => R.when(isNilOrEmpty, R.always(or))(search.get(k));

  const history = useHistory();

  const [query, setQuery] = useState({
    siret: getFromQuery('siret'),
    gamme: getGamme(getFromQuery('offre', 'sante')),
    college: getCollege(getFromQuery('college', 'C')),
    oavIdcc: getFromQuery('idcc'),
    siretQueryParamIsDefined: !isNilOrEmpty(getFromQuery('siret')) && siret.isSIRET(getFromQuery('siret')),
    idcc: undefined,
    ccnRef: undefined
  });

  const getQueryParam = queryParam => R.propOr('', queryParam, query);
  const setQueryParam = (queryParam, value) => setQuery(R.assoc(queryParam, value));

  useEffect(() => {
    search.set('siret', query.siret);
    search.set('college', query.college);
    search.set('offre', query.gamme);
    history.replace(`/service/comparateur?${search.toString()}`);
  }, [query]);

  return {
    getQueryParam,
    setQueryParam,
    query
  };
};

const HomePage = () => {
  const {
    setQueryParam,
    query
  } = useQuery();

  useMetric('ACCESS_PAGE_COMPARISON');

  return <Offer
    query={query}
    setQueryParam={setQueryParam}
  />;
};

export default HomePage;
