/**
 * @flow
 * @relayHash 773b9852bfed8bc355e412986985e69a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QMatchingQueryVariables = {||};
export type QMatchingQueryResponse = {|
  +matchingIframeUrl: ?string
|};
export type QMatchingQuery = {|
  variables: QMatchingQueryVariables,
  response: QMatchingQueryResponse,
|};
*/


/*
query QMatchingQuery {
  matchingIframeUrl
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "matchingIframeUrl",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QMatchingQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QMatchingQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "773b9852bfed8bc355e412986985e69a",
    "metadata": {},
    "name": "QMatchingQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '85011b317f65b25987d3e147c51b538c';

module.exports = node;
