/**
 * @flow
 * @relayHash c7c53fd61db06e914fa6e3c650b657e4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CollegeEnum = "C" | "NC" | "TOUS" | "%future added value";
export type GammeEnum = "prev" | "sante" | "%future added value";
export type useIsNafForbiddenQueryVariables = {|
  gamme?: ?GammeEnum,
  naf?: ?string,
  college?: ?CollegeEnum,
|};
export type useIsNafForbiddenQueryResponse = {|
  +nafIsForbidden: ?boolean
|};
export type useIsNafForbiddenQuery = {|
  variables: useIsNafForbiddenQueryVariables,
  response: useIsNafForbiddenQueryResponse,
|};
*/


/*
query useIsNafForbiddenQuery(
  $gamme: GammeEnum
  $naf: String
  $college: CollegeEnum
) {
  nafIsForbidden(gamme: $gamme, naf: $naf, college: $college)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "college"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gamme"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "naf"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "college",
        "variableName": "college"
      },
      {
        "kind": "Variable",
        "name": "gamme",
        "variableName": "gamme"
      },
      {
        "kind": "Variable",
        "name": "naf",
        "variableName": "naf"
      }
    ],
    "kind": "ScalarField",
    "name": "nafIsForbidden",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useIsNafForbiddenQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useIsNafForbiddenQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "c7c53fd61db06e914fa6e3c650b657e4",
    "metadata": {},
    "name": "useIsNafForbiddenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aa5686458ee44cc2fc899bb02985461d';

module.exports = node;
