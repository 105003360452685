/**
 * @flow
 * @relayHash 0117a9152b48884d4a8ddeae28f8302c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GammeEnum = "prev" | "sante" | "%future added value";
export type withDataQueryVariables = {||};
export type withDataQueryResponse = {|
  +garantyRefs: ?$ReadOnlyArray<?{|
    +ref: ?string,
    +garantyGamme: ?GammeEnum,
    +garantyGroup: ?string,
    +garantyGroupLabel: ?string,
    +label: ?string,
    +linkedRefs: ?$ReadOnlyArray<?string>,
  |}>
|};
export type withDataQuery = {|
  variables: withDataQueryVariables,
  response: withDataQueryResponse,
|};
*/


/*
query withDataQuery {
  garantyRefs {
    ref
    garantyGamme
    garantyGroup
    garantyGroupLabel
    label
    linkedRefs
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GarantyRefs",
    "kind": "LinkedField",
    "name": "garantyRefs",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ref",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "garantyGamme",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "garantyGroup",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "garantyGroupLabel",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "linkedRefs",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "withDataQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "withDataQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "0117a9152b48884d4a8ddeae28f8302c",
    "metadata": {},
    "name": "withDataQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2f2770cfb17a71ea1d61f3a3fbe14539';

module.exports = node;
