import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '..';
import { populateChildren } from './toolbox';
import { queryPropTypes } from '../../lib/propTypes';

const QMatchingQuery = graphql`
  query QMatchingQuery {
    matchingIframeUrl
  }
`;

const QMatching = ({ children, args, mockData }) => {
  return <Query
    query={QMatchingQuery}
    args={args}
    mockKey="QMatching"
    mockData={mockData}
  >
    {populateChildren('matchingIframeUrl')(children)}
  </Query>;
};

QMatching.propTypes = queryPropTypes;

export default QMatching;
