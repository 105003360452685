import { graphql } from 'react-relay';
import useFetchQuery from './useFetchQuery';

const QEnvQuery = graphql`
  query useEnvQuery($envVar: EnvEnum!) {
    env(envVar: $envVar)
  }
`;

const useEnv = (envVar) => {
  return useFetchQuery({
    query: QEnvQuery,
    dataProp: 'env',
    args: { envVar },
    defaultData: null
  });
};

export default useEnv;
