import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Company from './components/Company';
import * as R from 'ramda';
import Comparisons from './components/Comparisons';
import useIsNafForbidden from '../../hooks/useIsNafForbidden';
import useEnv from '../../hooks/useEnv';

const useOffer = (query) => {
  const [naf, setNaf] = useState();

  const [isNafForbidden, refetch] = useIsNafForbidden({
    naf,
    gamme: query.gamme,
    college: query.college
  });

  useEffect(() => {
    if (!R.either(R.isNil, R.isEmpty)(naf)) {
      refetch();
    }
  }, [naf, query.gamme, query.college]);

  return {
    isNafForbidden,
    setNaf
  };
};

const Offer = ({
  query,
  setQueryParam
}) => {
  const {
    isNafForbidden,
    setNaf
  } = useOffer(query);

  const [disableMatching] = useEnv('DISABLE_MATCHING');

  return <div>
    <div className="flex">
      <img
        src="/image/logo.jpg"
        className="w-200px"
      />

      <div className="w-full text-center h-65px leading-65px bg-primary text-white text-2xl font-bold ml-25px rounded-bl-3xl">
        Validation des points de conformité

        {!R.either(R.isNil, R.equals('on'))(disableMatching) && <div className="float-right">
          <a
            href="/service/datastore"
            style={{
              color: 'white',
              fontSize: '12px',
              paddingRight: '15px',
              textDecoration: 'underline'
            }}
          >
            Accéder au matching
          </a>
        </div>}

      </div>
    </div>

    <Company
      query={query}
      setQueryParam={setQueryParam}
      setNaf={setNaf}
      isNafForbidden={isNafForbidden}
    />

    {!R.isNil(query.idcc) && !isNafForbidden && <>
      <Comparisons
        idcc={query.idcc}
        ccnRef={query.ccnRef}
        gamme={query.gamme}
        college={query.college}
      />
    </>}
  </div>;
};

Offer.propTypes = {
  query: PropTypes.shape({
    siret: PropTypes.string,
    college: PropTypes.string,
    gamme: PropTypes.string,
    idcc: PropTypes.string,
    oavIdcc: PropTypes.string,
    ccnRef: PropTypes.string,
    siretQueryParamIsDefined: PropTypes.bool
  }).isRequired,
  setQueryParam: PropTypes.func.isRequired
};

export default Offer;
