import { useEffect, useState } from 'react';
import { isEmpty, is } from 'ramda';

const useKeyPressEnter = (fn, condition, args = {}) => {
  const [executed, setExecuted] = useState(false);

  useEffect(() => {
    if (!isEmpty(args)) {
      setExecuted(false);
    }
  }, [args]);

  useEffect(() => {
    const listener = event => {
      if (condition && event.key === 'Enter' && is(Function, fn) && !executed) {
        setExecuted(true);
        fn();
      }
    };

    window.addEventListener('keypress', listener);

    return () => {
      window.removeEventListener('keypress', listener);
    };
  }, [fn, condition, executed]);
};

export default useKeyPressEnter;
