import React from 'react';
import * as R from 'ramda';
import useEnv from '../../hooks/useEnv';
import Loading from '../../components/Loading';
import useMetric from '../../hooks/useMetric';

const Ernest = () => {
  const [ernestUrl] = useEnv('SERVICE_ERNEST_URL');

  useMetric('ACCESS_PAGE_ERNEST');

  if (R.isNil(ernestUrl)) {
    return <Loading/>;
  }

  if (R.isEmpty(ernestUrl)) {
    return <span>Une erreur est survenue.</span>;
  }

  return <iframe
    style={{
      width: '100%',
      height: '99.2vh',
      border: 'none'
    }}
    src={ernestUrl}
  />;
};

export default Ernest;
