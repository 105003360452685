/**
 * @flow
 * @relayHash 86b3997161cc29ad11ab9a51dab9b6c7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MetricKeyEnum = "ACCESS_PAGE_COMPARISON" | "ACCESS_PAGE_ERNEST" | "ACCESS_PAGE_MATCHING" | "%future added value";
export type IncrementMetricMutationVariables = {|
  key: MetricKeyEnum
|};
export type IncrementMetricMutationResponse = {|
  +incrementMetric: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type IncrementMetricMutation = {|
  variables: IncrementMetricMutationVariables,
  response: IncrementMetricMutationResponse,
|};
*/


/*
mutation IncrementMetricMutation(
  $key: MetricKeyEnum!
) {
  incrementMetric(key: $key) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "key",
        "variableName": "key"
      }
    ],
    "concreteType": "BasicMutationResponse",
    "kind": "LinkedField",
    "name": "incrementMetric",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IncrementMetricMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IncrementMetricMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "86b3997161cc29ad11ab9a51dab9b6c7",
    "metadata": {},
    "name": "IncrementMetricMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2183224236ed6c32070e57539402d973';

module.exports = node;
