import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
import Pages from './pages';
import Matching from './pages/matching';
import Ernest from './pages/ernest';
import GQLEnvironment from './_graphql/Environment';
import { DataProvider } from './withData';
const { RelayEnvironmentProvider } = require('react-relay/hooks');

const App = () => {
  return <RelayEnvironmentProvider environment={GQLEnvironment.get()}>
    <DataProvider>
      <Router>
        <Switch>

          <Route path="/service/datastore" component={Matching}/>
          <Route path="/service/ernest" component={Ernest}/>
          <Route path="/service/comparateur" component={Pages}/>
          <Redirect to="/service/comparateur"/>

        </Switch>
      </Router>
    </DataProvider>
  </RelayEnvironmentProvider>;
};

export default hot(App);
