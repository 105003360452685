import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '..';
import { populateChildren } from './toolbox';
import { queryPropTypes } from '../../lib/propTypes';

const QCollegeSplittingQuery = graphql`
  query QCollegeSplittingQuery($idcc: String!, $gamme: GammeEnum!) {
    collegeSplitting(idcc: $idcc, gamme: $gamme)
  }
`;

const QCollegeSplitting = ({ children, args, mockData }) => {
  return <Query
    query={QCollegeSplittingQuery}
    args={args}
    mockKey="QCollegeSplitting"
    mockData={mockData}
  >
    {populateChildren('collegeSplitting')(children)}
  </Query>;
};

QCollegeSplitting.propTypes = queryPropTypes;

export default QCollegeSplitting;
