import { useEffect } from 'react';
import * as R from 'ramda';
import IncrementMetricMutation from '../_graphql/mutations/IncrementMetricMutation';

const useMetric = metric => {
  useEffect(() => {
    if (!R.isNil(metric)) {
      IncrementMetricMutation(metric, (ok, error) => {
        if (!ok || error) {
          console.error(`unable to increment metric ${metric}`, error);
        }
      });
    }
  }, []);
};

export default useMetric;
