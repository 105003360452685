import React from 'react';
import PropTypes from 'prop-types';
import Row from '../../../../components/Row';
import Title from '../../../../components/Title';
import LabelValue from '../../../../components/LabelValue';
import {
  replace,
  propOr,
  defaultTo,
  find,
  path,
  nth,
  when,
  isNil,
  always,
  trim,
  map,
  compose,
  isEmpty,
  propEq,
  includes,
  __
} from 'ramda';
import { idccIs, IDCCS } from '../../../../data/idccs';

const labelIs = (label, labelsList) => compose(
  includes(__, labelsList),
  trim
)(label);

const Prices = ({
  prices,
  beneficiaries,
  college,
  gamme,
  idcc
}) => {
  if (isEmpty(prices)) {
    return null;
  }

  // Pour le collège cadre en prévoyance :
  //   - Si le tarif TA est inférieur à 1.50 OU si pas de tarif, il faut afficher 1.50% pour le TA
  const priceTA = compose(
    parseFloat,
    replace('%', ''),
    replace(/ /g, ''),
    propOr('0', 'value'),
    defaultTo({ value: '0' }),
    find(propEq('label', 'TA')),
    defaultTo([]),
    path([0, 'prices']),
    defaultTo([])
  )(prices);

  const overridePrices = college === 'C' && gamme === 'prev' && (isEmpty(priceTA) || isNaN(priceTA) || priceTA < 1.50);

  prices = compose(
    propOr([], 'prices'),
    when(isNil, always({})),
    nth(0)
  )(prices);

  return <Row
    maxColumns={{ xl: 2, lg: 2, md: 1, sm: 1 }}
    className="w-8/12 mx-auto"
  >
    <div className="mx-auto">
      <Title>
        Les taux de cotisation inscrits dans la CCN
      </Title>

      {map(({ label, value }) => {
        if (labelIs(label, ['TA']) && overridePrices) {
          value = '1.50 %';
        }

        if ((idccIs(idcc, IDCCS.METALLURGIE_ISERE_HAUTES_ALPES) || idccIs(idcc, IDCCS.METALLURGIE)) && labelIs(label, ['TA', 'TB', 'TC'])) {
          value = college === 'C' ? '1.12 %' : '0.6%';
        }

        return <div key={label} className="my-5px">
          <LabelValue
            label={label}
            value={value}
          />
        </div>;
      })(prices)}

    </div>

    <div className="mx-auto text-justify">
      <Title>
        Bénéficiaires
      </Title>
      <div style={{ marginTop: '25px' }}>{beneficiaries}</div>
    </div>
  </Row>;
};

Prices.propTypes = {
  prices: PropTypes.array.isRequired,
  beneficiaries: PropTypes.string,
  college: PropTypes.string.isRequired,
  gamme: PropTypes.string.isRequired
};

export default Prices;
