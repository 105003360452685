import React, { useEffect } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

const SwitchValue = ({
  label,
  value,
  onChange,
  options = []
}) => {
  const firstOption = R.propOr({ label: '', value: '' }, 0, options);
  const secondOption = R.propOr({ label: '', value: '' }, 1, options);

  useEffect(() => {
    if (!R.either(R.isNil, R.isEmpty)(options)) {
      const allowedValues = R.pluck('value', options);

      if (!R.includes(value, allowedValues) && !R.isEmpty(allowedValues)) {
        onChange(allowedValues[0]);
      }
    }
  }, [options]);

  const classNames = {
    switchValue: 'flex ml-20px mr-50px border border-primary rounded-2xl cursor-pointer select-none text-primaryLight',
    div: 'py-2px px-40px',
    switchValueSelected: optionValue => optionValue === value ? 'bg-primary rounded-2xl text-white' : ''
  };

  return <div className="flex">
    <div>{label}</div>

    {R.length(options) === 2 && <div className={classNames.switchValue}>
      <div
        className={`${classNames.div} ${classNames.switchValueSelected(firstOption.value)}`}
        onClick={() => onChange(firstOption.value)}
      >
        {firstOption.label}
      </div>

      <div
        className={`${classNames.div} ${classNames.switchValueSelected(secondOption.value)}`}
        onClick={() => onChange(secondOption.value)}
      >
        {secondOption.label}
      </div>
    </div>}

    {R.length(options) === 1 && <div className={classNames.switchValue}>
      <div
        className={`${classNames.div} ${classNames.switchValueSelected(value)}`}
      >
        {firstOption.label}
      </div>
    </div>}
  </div>;
};

SwitchValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired
};

export default SwitchValue;
