import * as R from 'ramda';

const formatSiret = siret => {
  if (R.isNil(siret)) return;

  if (R.is(Number, siret)) siret = siret.toString();

  siret = R.replace(/ /g, '')(siret);

  const [siren, nic] = R.splitAt(9, siret.toString());

  const value = R.concat(
    R.splitEvery(3, siren),
    [nic]
  );

  return R.join(' ', value);
};

export {
  formatSiret
};
