/**
 * @flow
 * @relayHash 03fadeab98a5a870f013f1fb9d3f6697
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GammeEnum = "prev" | "sante" | "%future added value";
export type QCollegeSplittingQueryVariables = {|
  idcc: string,
  gamme: GammeEnum,
|};
export type QCollegeSplittingQueryResponse = {|
  +collegeSplitting: ?$ReadOnlyArray<?string>
|};
export type QCollegeSplittingQuery = {|
  variables: QCollegeSplittingQueryVariables,
  response: QCollegeSplittingQueryResponse,
|};
*/


/*
query QCollegeSplittingQuery(
  $idcc: String!
  $gamme: GammeEnum!
) {
  collegeSplitting(idcc: $idcc, gamme: $gamme)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gamme"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "idcc"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "gamme",
        "variableName": "gamme"
      },
      {
        "kind": "Variable",
        "name": "idcc",
        "variableName": "idcc"
      }
    ],
    "kind": "ScalarField",
    "name": "collegeSplitting",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QCollegeSplittingQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QCollegeSplittingQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "03fadeab98a5a870f013f1fb9d3f6697",
    "metadata": {},
    "name": "QCollegeSplittingQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6bd74ba269b21c14c7248aea4d1fd6a5';

module.exports = node;
