import { graphql } from 'react-relay';
import useFetchQuery from './useFetchQuery';

const QForbiddenNafQuery = graphql`
  query useIsNafForbiddenQuery($gamme: GammeEnum, $naf: String, $college: CollegeEnum) {
    nafIsForbidden(gamme: $gamme, naf: $naf, college: $college)
  }
`;

const useIsNafForbidden = ({ gamme, naf, college }) => {
  return useFetchQuery({
    query: QForbiddenNafQuery,
    dataProp: 'nafIsForbidden',
    args: { gamme, naf, college },
    defaultData: false
  });
};

export default useIsNafForbidden;
