import React, { useEffect, useRef } from 'react';
import { useQueryLoader, usePreloadedQuery, RelayEnvironmentProvider, useRelayEnvironment } from 'react-relay/hooks';
import mock from './mock';
import PropTypes from 'prop-types';
import { childrenPropTypes } from '../lib/propTypes';
import Loading from '../components/Loading';

const R = require('ramda');

const renderChildren = (children, data) => {
  if (R.is(Function, children)) return children(data);
  if (!R.is(Function, children)) return React.cloneElement(children, data);
};

const Populate = ({
  query,
  queryReference,
  children
}) => {
  const data = usePreloadedQuery(query, queryReference);
  return renderChildren(children, data);
};

const QueryProxy = ({
  query,
  args,
  mockKey,
  mockData,
  children,
  noFallback,
  fetchPolicy
}) => {
  const environment = useRelayEnvironment();

  const mockDataFn = R.is(Function, mockData) ? mockData(environment) : process.env.MOCK_ENABLED === 'true' ? mock(mockKey)(environment) : null;

  if (!R.isNil(environment)) {
    return (
      <RelayEnvironmentProvider environment={environment}>
        <Query
          query={query}
          args={args}
          mockData={mockDataFn}
          noFallback={noFallback}
          fetchPolicy={fetchPolicy}
        >
          {children}
        </Query>
      </RelayEnvironmentProvider>
    );
  }
  return (
    <Query
      query={query}
      args={args}
      mockData={mockDataFn}
      noFallback={noFallback}
      fetchPolicy={fetchPolicy}
    >
      {children}
    </Query>
  );
};

const Query = ({
  query,
  args,
  children,
  mockData,
  noFallback = false,
  fetchPolicy = 'store-or-network'
}) => {
  const [
    queryReference,
    loadQuery
  ] = useQueryLoader(query);

  const argsRef = useRef('_');

  useEffect(() => {
    if (argsRef.current === '_' || !R.equals(argsRef.current, args)) {
      argsRef.current = args;
    } else {
      return;
    }

    if (R.is(Function, mockData)) mockData(query, args);

    loadQuery(args, { fetchPolicy });
  }, [query, mockData, ...R.values(args)]);

  if (R.isNil(queryReference)) {
    if (noFallback) {
      return renderChildren(children, { loading: true });
    }
    return <Loading/>;
  }

  return (
    <React.Suspense fallback={noFallback ? renderChildren(children, { loading: true }) : <Loading/>}>
      <Populate queryReference={queryReference} query={query}>
        {children}
      </Populate>
    </React.Suspense>
  );
};

QueryProxy.propTypes = {
  args: PropTypes.object,
  children: childrenPropTypes,
  mockData: PropTypes.func,
  query: PropTypes.object.isRequired,
  mockKey: PropTypes.string,
  noFallback: PropTypes.bool
};

Query.propTypes = {
  args: PropTypes.object,
  children: childrenPropTypes,
  mockData: PropTypes.func,
  query: PropTypes.object.isRequired,
  noFallback: PropTypes.bool
};

export default QueryProxy;
