import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

const mapIndexed = R.addIndex(R.map);
const isNilOrEmpty = R.either(R.isNil, R.isEmpty);
const defaultValue = '-';

const isNotReinforced = R.compose(
  R.not,
  R.equals('R'),
  R.last,
  R.prop('rawOfferOptionName')
);

const garantyGroupsOrder = [
  'deces_ptia',
  'frais_obseques',
  'rente_conjoint',
  'rente_education',
  'incapacite_temporaire_travail',
  'invalidite_permanente',
  'autres'
];

const getGarantyGroupLabel = (group, label) => R.propOr(label, group, {
  prevention_et_non_SS: 'Prévention et médecines alternatives',
  forfait: 'Forfait naissance - Cures thermales'
});

const updateGarantyGroupLabel = garantyRef => {
  return R.assoc('garantyGroupLabel', getGarantyGroupLabel(garantyRef.garantyGroup, garantyRef.garantyGroupLabel))(garantyRef);
};

const mutateValue = ref => value => {
  return R.propOr(R.always(value), ref)({
    P18: R.compose(
      R.when(
        R.compose(
          R.not,
          R.anyPass([
            R.isNil,
            R.isEmpty,
            R.equals('-')
          ])
        ),
        R.always('Oui')
      )
    )
  })(value);
};

const getBestValue = (currentBestValue, value) => {
  const bestValueInt = parseInt(currentBestValue.value);
  const linkedValueInt = parseInt(value.value);

  if (R.anyPass([R.isNil, R.isEmpty, R.equals('-'), R.equals('à définir')])(currentBestValue.fullValue)) {
    return value;
  }

  if ((!R.isNil(currentBestValue.unit) && !R.propEq('unit', currentBestValue.unit, value)) || isNaN(linkedValueInt)) {
    return currentBestValue;
  }

  if (isNaN(bestValueInt) || linkedValueInt > bestValueInt) {
    return value;
  }

  return currentBestValue;
};

const useComparisonResult = ({
  comparisons,
  garantyRefs,
  rawOffers,
  gamme
}) => {
  const groupRefs = gamme === 'prev'
    ? R.compose(
      R.reject(R.isNil),
      R.map(garantyGroup => {
        const refs = R.filter(R.propEq('garantyGroup', garantyGroup))(garantyRefs);

        if (R.isEmpty(refs)) {
          return null;
        }

        const garantyGroupLabel = R.pathOr(garantyGroup, [0, 'garantyGroupLabel'])(refs);
        return [garantyGroupLabel, refs];
      })
    )(garantyGroupsOrder)
    : R.compose(
      R.toPairs,
      R.groupBy(R.prop('garantyGroupLabel')),
      R.map(updateGarantyGroupLabel)
    )(garantyRefs);

  const selectedRawOfferOption = R.compose(
    R.when(R.isNil, R.always({})),
    R.find(R.propEq('selected', true))
  )(rawOffers);
  const selectedRawOfferOptionIndex = R.findIndex(R.propEq('selected', true))(rawOffers);

  const getOptionName = (option, defaultName = '') => R.compose(
    R.propOr(defaultName, 'rawOfferOptionName'),
    R.when(R.isNil, R.always({}))
  )(option);

  const comparisonsRefs = R.compose(
    R.flatten,
    R.pluck('refs')
  )(comparisons);

  const linkedRefs = R.compose(
    R.pluck('ref'),
    R.reject(
      R.compose(
        R.isEmpty,
        R.propOr([], 'linkedRefs')
      )
    )
  )(garantyRefs);

  const getOptionRefValue = ref => index => {
    if (index === -1) {
      return '-';
    }

    return R.compose(
      mutateValue(ref),
      R.when(R.equals('à définir'), R.always(defaultValue)),
      R.when(R.isEmpty, R.always(defaultValue)),
      R.propOr(defaultValue, 'value'),
      R.when(R.isNil, R.always({})),
      R.nth(index),
      R.propOr([], 'rawOffersItem'),
      R.when(R.isNil, R.always({})),
      R.find(R.propEq('ref', ref))
    )(comparisonsRefs);
  };

  const getComputedValue = ref => {
    const foundRef = R.compose(
      R.when(R.isNil, R.always({})),
      R.find(R.propEq('ref', ref))
    )(comparisonsRefs);

    return R.compose(
      R.assoc('valueRef', ref),
      R.assoc('fullValue', R.prop('value', foundRef)),
      R.propOr({}, 'computedValue')
    )(foundRef);
  };

  const getRefValue = ref => {
    const foundRef = R.compose(
      R.when(R.isNil, R.always({})),
      R.find(R.propEq('ref', ref))
    )(comparisonsRefs);

    const computedValue = R.compose(
      R.assoc('valueRef', ref),
      R.assoc('fullValue', R.prop('value', foundRef)),
      R.propOr({}, 'computedValue')
    )(foundRef);

    const linkedRefs = R.compose(
      R.propOr([], 'linkedRefs'),
      R.when(R.isNil, R.always({})),
      R.find(R.propEq('ref', ref))
    )(garantyRefs);

    let linkedValues = [];

    if (!R.isEmpty(linkedRefs)) {
      linkedValues = R.map(linkedRef => {
        const foundRef = R.compose(
          R.when(R.isNil, R.always({})),
          R.find(R.propEq('ref', linkedRef))
        )(comparisonsRefs);

        return R.compose(
          R.assoc('valueRef', linkedRef),
          R.assoc('fullValue', R.prop('value', foundRef)),
          R.propOr({}, 'computedValue')
        )(foundRef);
      })(linkedRefs);
    }

    let { fullValue, valueRef } = R.reduce((currentBestValue, linkedValue) => {
      return getBestValue(currentBestValue, linkedValue);
    }, computedValue)(linkedValues);

    if (ref === 'P5' || ref === 'P6') {
      const result = getBestValue(computedValue, getComputedValue('P9'));
      fullValue = result.fullValue;
      valueRef = result.valueRef;
    }

    if (ref === 'P7') {
      const result = getBestValue(computedValue, getComputedValue('P8'));
      fullValue = result.fullValue;
      valueRef = result.valueRef;
    }

    return {
      valueRef,
      value: R.compose(
        mutateValue(ref),
        R.when(R.equals('à définir'), R.always(defaultValue)),
        R.when(R.either(R.isNil, R.isEmpty), R.always(defaultValue))
      )(fullValue)
    };
  };

  const getValueClassName = ref => index => {
    if (index === -1) {
      return '';
    }

    let result = '=';

    if (!R.includes(ref, linkedRefs)) {
      result = R.compose(
        R.pathOr('=', ['comparison', 'result']),
        R.when(R.isNil, R.always({})),
        R.nth(index),
        R.propOr([], 'rawOffersItem'),
        R.when(R.isNil, R.always({})),
        R.find(R.propEq('ref', ref))
      )(comparisonsRefs);
    } else {
      const item = R.compose(
        R.when(R.isNil, R.always({})),
        R.nth(index),
        R.propOr([], 'rawOffersItem'),
        R.when(R.isNil, R.always({})),
        R.find(R.propEq('ref', ref))
      )(comparisonsRefs);

      const computedValue = R.propOr({}, 'computedValue')(item);

      const { valueRef } = getRefValue(ref);

      if (!R.isNil(valueRef)) {
        const refItem = R.compose(
          R.when(R.isNil, R.always({})),
          R.find(R.propEq('ref', valueRef))
        )(comparisonsRefs);
        const computedValueRef = R.propOr({}, 'computedValue')(refItem);

        if (R.eqProps('unit', computedValue, computedValueRef) && R.eqProps('base', computedValue, computedValueRef) && !isNilOrEmpty(computedValue.value) && !isNilOrEmpty(computedValueRef.value) && ref !== valueRef && !R.propSatisfies(R.includes('% BR + '), 'value')(refItem)) {
          const refValue = parseFloat(computedValueRef.value);
          const optionValue = parseFloat(computedValue.value);

          if (!isNaN(refValue) && !isNaN(optionValue)) {
            result = refValue < optionValue ? '+' : refValue > optionValue ? '-' : '=';
          }
        } else {
          result = R.pathOr('=', ['comparison', 'result'])(item);
        }
      }
    }

    if (result === '+') {
      return ' table-comparison-value-plus';
    }
    if (result === '-') {
      return ' table-comparison-value-minus';
    }
    return '';
  };

  const hasValues = ref => R.compose(
    R.not,
    R.both(R.propEq(0, defaultValue), v => R.length(v) === 1),
    R.uniq,
    R.append(getRefValue(ref)),
    mapIndexed((option, index) => getOptionRefValue(ref)(index))
  )(rawOffers);

  return {
    getOptionName,
    getOptionRefValue,
    getRefValue,
    getValueClassName,
    groupRefs,
    hasValues,
    selectedRawOfferOptionIndex,
    selectedRawOfferOption
  };
};

const ComparisonResult = ({
  comparisons,
  rawOffers,
  garantyRefs,
  comparisonType,
  gamme,
  showOnlyRefOffer
}) => {
  if (showOnlyRefOffer) {
    rawOffers = [];
  }

  const {
    getOptionName,
    getOptionRefValue,
    getRefValue,
    groupRefs,
    hasValues,
    selectedRawOfferOptionIndex,
    selectedRawOfferOption,
    getValueClassName
  } = useComparisonResult({
    garantyRefs,
    rawOffers,
    comparisons,
    gamme
  });

  if (comparisonType === 'modular') {
    rawOffers = [];
  }

  return <div className="overflow-x-auto">
    <table className="table-comparison mx-auto">
      <tbody>
        <tr className="table-comparison-header">

          <td className="table-comparison-header-hidden">{gamme === 'prev' ? <span className="text-black font-normal"><b>SAB : </b>Salaire Annuel Brut</span> : null}</td>
          <td className="table-comparison-header-hidden"/>

          {!showOnlyRefOffer && <>
            <td>{comparisonType === 'modular' ? 'A LA CARTE' : getOptionName(selectedRawOfferOption, 'Aucune offre sélectionnée')}</td>
            <td className="table-comparison-hidden"/>
            {R.map(option => (
              isNotReinforced(option) && <td key={`name${option.rawOfferOptionId}`}>{getOptionName(option)}</td>
            ))(rawOffers)}
          </>}

        </tr>

        {R.map(([garantyGroupLabel, refs]) => {
          return <React.Fragment key={garantyGroupLabel}>
            <tr className="table-comparison-garantygroup">
              <td>{garantyGroupLabel}</td>
              <td>Minimum conventionnel</td>

              {!showOnlyRefOffer && <>
                <td>Offre minimale recommandée</td>

                <td className="table-comparison-hidden"/>

                {R.map((option) => (
                  isNotReinforced(option) && <td key={`${garantyGroupLabel}${option.rawOfferOptionId}`}/>
                ))(rawOffers)}
              </>}

            </tr>

            {R.map(({ label, ref }) => {
              const getValue = getOptionRefValue(ref);
              const getClassname = getValueClassName(ref);

              if (!hasValues(ref)) {
                return null;
              }

              return <tr key={ref} className="table-comparison-ref">
                <td>{label}</td>
                <td>{getRefValue(ref).value}</td>

                {!showOnlyRefOffer && <>
                  <td className={`table-comparison-selected${getClassname(selectedRawOfferOptionIndex)}`}>{getValue(selectedRawOfferOptionIndex)}</td>

                  <td className="table-comparison-hidden"/>

                  {mapIndexed((option, index) => (
                    isNotReinforced(option) && <td className={getClassname(index)} key={`${ref}${option.rawOfferOptionId}`}>{getValue(index)}</td>
                  ))(rawOffers)}
                </>}

              </tr>;
            })(refs)}
          </React.Fragment>;
        })(groupRefs)}
      </tbody>
    </table>
  </div>;
};

ComparisonResult.propTypes = {
  comparisons: PropTypes.array.isRequired,
  rawOffers: PropTypes.arrayOf(PropTypes.object).isRequired,
  garantyRefs: PropTypes.arrayOf(PropTypes.object).isRequired,
  comparisonType: PropTypes.string.isRequired,
  gamme: PropTypes.oneOf(['sante', 'prev']),
  showOnlyRefOffer: PropTypes.bool.isRequired
};

export default ComparisonResult;
