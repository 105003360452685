import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import QSiretInfo from '../../../_graphql/queries/QSiretInfo';
import Ccn from './Ccn';
import LabelValue from '../../../components/LabelValue';
import SwitchValue from '../../../components/SwitchValue';
import { formatSiret } from '../../../lib/format';
import LabelInput from '../../../components/LabelInput';
import { gammes } from '../../../data';
import Checkbox from '../../../components/Checkbox';
import Alert from '../../../components/Alert';
import Title from '../../../components/Title';
import Row from '../../../components/Row';
import QCollegeSplitting from '../../../_graphql/queries/QCollegeSplitting';
const { isSIRET } = require('siret');

const isDefined = R.complement(R.either(R.isNil, R.isEmpty));

const useCompany = ({ query, setQueryParam }) => {
  const [companyQuery, setCompanyQuery] = useState({
    siret: query.siret,
    idcc: undefined
  });
  const [hasNoSiret, setHasNoSiret] = useState(false);

  const onUpdateSiret = siret => {
    setQueryParam('siret', siret);
    setQueryParam('idcc', undefined);
    setCompanyQuery(R.compose(
      R.assoc('siret', siret),
      R.assoc('idcc', undefined)
    ));
  };

  const updateIdcc = (idcc, ccnRef) => {
    setQueryParam('idcc', idcc);
    setQueryParam('ccnRef', ccnRef);
    setCompanyQuery(R.assoc('idcc', idcc));
  };

  const withQuery = isDefined(companyQuery.idcc) || isDefined(companyQuery.siret);

  return {
    companyQuery,
    onUpdateSiret,
    updateIdcc,
    hasNoSiret,
    setHasNoSiret,
    withQuery
  };
};

const Company = ({
  siretInfo,
  query,
  setQueryParam,
  setNaf,
  setHasNoSiret,
  hasNoSiret,
  isNafForbidden,
  onUpdateSiret,
  updateIdcc
}) => {
  const { name, ape, siret, ccns = [] } = R.when(R.isNil, R.always({}))(siretInfo);
  const hasData = !R.either(R.isNil, R.isEmpty)(siretInfo);

  useEffect(() => {
    if (R.isNil(query.idcc) && R.length(ccns) === 1) {
      const { idcc, ccnRef } = R.pathOr({}, ['ccns', 0])(siretInfo);
      setQueryParam('idcc', idcc, siretInfo);
      setQueryParam('ccnRef', ccnRef, siretInfo);
    }

    if (!R.isNil(ape)) {
      setNaf(ape);
    }
  }, [ccns, ape]);

  const getColleges = (collegeSplitting) => {
    return R.filter(
      R.propSatisfies(R.includes(R.__, collegeSplitting), 'value')
    )([
      { value: 'TOUS', label: 'Ensemble du personnel' },
      { value: 'C', label: 'Cadres' },
      { value: 'NC', label: 'Non cadres' }
    ]);
  };

  const idcc = R.propOr(R.path(['ccns', 0, 'idcc'], siretInfo), 'idcc')(query);

  return <>
    {hasData && <Title small>Rappel des informations :</Title>}

    <Row maxColumns={{ xl: 3, lg: 2, md: 1 }}>
      <LabelInput
        label="Siret"
        value={R.defaultTo('', siret)}
        formatting={formatSiret}
        width={260}
        onChange={onUpdateSiret}
        placeholder="Saisir un SIRET..."
        isValid={isSIRET}
        readOnly={query.siretQueryParamIsDefined}
      />

      {!hasData && (
        <Checkbox
          size={22}
          label="L'entreprise n'a pas encore de SIRET"
          onChange={setHasNoSiret}
        />
      )}

      {hasData && <>
        <LabelValue
          label="Nom de l'entreprise"
          value={name}
          className="flex"
        />
        <LabelValue
          label="Code APE"
          value={ape}
          style={isNafForbidden ? { color: 'red' } : {}}
        />
      </>}
    </Row>

    {hasData && isNafForbidden && (
      <Alert
        type="warning"
        message="La souscription de l’offre AFFIPRO MMA Prévoyance Collective n’est pas autorisée pour ce code NAF"
      />
    )}

    {(hasData || hasNoSiret) && (
      <Ccn
        ccns={ccns}
        oavIdcc={query.oavIdcc}
        onChange={updateIdcc}
        gamme={query.gamme}
      />
    )}

    {(hasData || hasNoSiret) && !R.isNil(idcc) && <QCollegeSplitting args={{
      idcc,
      gamme: query.gamme
    }}>
      {collegeSplitting => {
        return <div className="grid sm:grid-cols-2 xs:grid-cols-1 gap-y-2">
          <SwitchValue
            label="Collège"
            value={query.college}
            onChange={v => setQueryParam('college', v)}
            options={getColleges(collegeSplitting)}
          />

          <SwitchValue
            label="Garantie"
            value={query.gamme}
            onChange={v => setQueryParam('gamme', v)}
            options={gammes}
          />
        </div>;
      }}
    </QCollegeSplitting>}
  </>;
};

const CompanyWrapper = ({
  query,
  setQueryParam,
  setNaf,
  isNafForbidden
}) => {
  const {
    companyQuery,
    onUpdateSiret,
    updateIdcc,
    hasNoSiret,
    setHasNoSiret,
    withQuery
  } = useCompany({ query, setQueryParam });

  return <div className="mt-40px p-25px lg:text-xl md:text-lg xs:text-sm">
    <Title>Les informations</Title>

    {withQuery && <QSiretInfo args={companyQuery}>
      <Company
        query={query}
        setQueryParam={setQueryParam}
        setNaf={setNaf}
        isNafForbidden={isNafForbidden}
        setHasNoSiret={setHasNoSiret}
        hasNoSiret={hasNoSiret}
        onUpdateSiret={onUpdateSiret}
        updateIdcc={updateIdcc}
      />
    </QSiretInfo>}

    {!withQuery && <Company
      query={query}
      setQueryParam={setQueryParam}
      setNaf={setNaf}
      isNafForbidden={isNafForbidden}
      setHasNoSiret={setHasNoSiret}
      hasNoSiret={hasNoSiret}
      onUpdateSiret={onUpdateSiret}
      updateIdcc={updateIdcc}
    />}
  </div>;
};

Company.propTypes = {
  setQueryParam: PropTypes.func.isRequired,
  query: PropTypes.shape({
    siret: PropTypes.string,
    college: PropTypes.string,
    gamme: PropTypes.string,
    idcc: PropTypes.string,
    oavIdcc: PropTypes.string,
    ccnRef: PropTypes.string,
    siretQueryParamIsDefined: PropTypes.bool
  }).isRequired,
  setNaf: PropTypes.func.isRequired,
  isNafForbidden: PropTypes.bool.isRequired,
  setHasNoSiret: PropTypes.func.isRequired,
  hasNoSiret: PropTypes.bool.isRequired,
  onUpdateSiret: PropTypes.func.isRequired,
  updateIdcc: PropTypes.func.isRequired,
  siretInfo: PropTypes.object
};

CompanyWrapper.propTypes = {
  setQueryParam: PropTypes.func.isRequired,
  query: PropTypes.shape({
    siret: PropTypes.string,
    college: PropTypes.string,
    gamme: PropTypes.string,
    idcc: PropTypes.string,
    oavIdcc: PropTypes.string,
    ccnRef: PropTypes.string,
    siretQueryParamIsDefined: PropTypes.bool
  }).isRequired,
  setNaf: PropTypes.func.isRequired,
  isNafForbidden: PropTypes.bool.isRequired
};

export default CompanyWrapper;
