import React from 'react';
import { graphql } from 'react-relay';
import { Query } from '..';
import { populateChildren } from './toolbox';
import { queryPropTypes } from '../../lib/propTypes';

const QRawOfferQuery = graphql`
  query QRawOfferQuery($rawOfferId: String!) {
    rawOffer(rawOfferId: $rawOfferId) {
      id
      rawOfferName
      versions {
        index
        options {
          id
          name
          items {
            classification {
              ref
              label
            }
            value
          }
        }
      }
      generalInformation {
        beneficiaries
        contributionDistribution
        extensionOrderDate
        amendmentsDate
        agreementDate
        extensionAmendmentsDate
        highDegreeOfSolidarity
        maintenanceOfGuaranteesOutsidePortability
      }
      prices {
        index
        prices {
          label
          value
        }
      }
    }
  }
`;

const QRawOffer = ({ children, args, mockData }) => {
  return <Query
    query={QRawOfferQuery}
    args={args}
    mockKey="QRawOffer"
    mockData={mockData}
  >
    {populateChildren('rawOffer')(children)}
  </Query>;
};

QRawOffer.propTypes = queryPropTypes;

export default QRawOffer;
