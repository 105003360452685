import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import QRawOffer from '../../../../_graphql/queries/QRawOffer';
import ComparisonResult from './ComparisonResult';
import GeneralInformations from '../GeneralInformations';
import ComparisonNotes from './ComparisonNotes';
import Prices from './Prices';
import withData from '../../../../withData';

const mapIndexed = R.addIndex(R.map);

const Comparison = ({
  comparison,
  gamme,
  college,
  showGeneralInformations,
  showOnlyRefOffer,
  getGarantyRefsByGamme,
  idcc
}) => {
  const notes = R.prop('notes', comparison);
  let rawOffers = R.propOr([], 'rawOffers')(comparison);
  const comparisons = R.propOr([], 'comparisons', comparison);
  const comparisonType = R.prop('comparisonType', comparison);

  // L'offre de référence est la première dans la liste
  const refRawOffer = R.compose(
    R.nth(0),
    R.take(1)
  )(rawOffers);

  rawOffers = R.compose(
    R.drop(1) // On retire la première offre
  )(rawOffers);

  return <QRawOffer args={{ rawOfferId: refRawOffer.rawOfferId }}>
    {/* On récupère l'offre de référence */}

    {data => {
      if (R.either(R.isNil, R.isEmpty)(data)) {
        return <span>Aucune offre trouvée.</span>;
      }

      return <>
        {showGeneralInformations && <Prices
          prices={R.propOr([], 'prices', data)}
          beneficiaries={R.pathOr('', ['generalInformation', 'beneficiaries'], data)}
          college={college}
          gamme={gamme}
          idcc={idcc}
        />}

        <ComparisonResult
          comparisons={comparisons}
          rawOffers={rawOffers}
          garantyRefs={getGarantyRefsByGamme(gamme)}
          comparisonType={comparisonType}
          gamme={gamme}
          showOnlyRefOffer={showOnlyRefOffer}
        />

        {!R.isNil(notes) && !R.isEmpty(notes) && (
          <ComparisonNotes
            notes={
              R.compose(
                mapIndexed((line, index) => {
                  const isLast = R.length(R.split('\n', notes)) === index + 1;
                  return <React.Fragment key={index}>
                    {line}
                    {!isLast && <br/>}
                  </React.Fragment>;
                }),
                R.dropLastWhile(R.isEmpty),
                R.split('\n')
              )(notes)
            }
          />
        )}

        {showGeneralInformations && <GeneralInformations rawOffer={data}/>}
      </>;
    }}
  </QRawOffer>;
};

Comparison.propTypes = {
  comparison: PropTypes.object.isRequired,
  gamme: PropTypes.string.isRequired,
  college: PropTypes.string.isRequired,
  showGeneralInformations: PropTypes.bool.isRequired,
  showOnlyRefOffer: PropTypes.bool.isRequired,
  getGarantyRefsByGamme: PropTypes.func.isRequired
};

export default withData(Comparison);
