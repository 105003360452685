import colleges from './colleges';
import gammes from './gammes';
import * as R from 'ramda';

const defaultGamme = 'sante';
const defaultCollege = 'C';

const gammeIsValid = gamme => R.compose(
  R.includes(gamme),
  R.pluck('value')
)(gammes);

const collegeIsValid = college => R.compose(
  R.includes(college),
  R.pluck('value')
)(colleges);

const getGamme = gamme => gammeIsValid(gamme) ? gamme : defaultGamme;
const getCollege = college => collegeIsValid(college) ? college : defaultCollege;

export {
  colleges,
  gammes,
  gammeIsValid,
  collegeIsValid,
  getCollege,
  getGamme
};
