import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Title from '../../../components/Title';

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

const classNames = {
  generalInformation: 'px-25px mb-30px text-justify'
};

const GeneralInformation = ({ label, value }) => {
  return <div className={classNames.generalInformation}>
    <Title className="mb-20px">
      {label}
    </Title>

    {isNilOrEmpty(value)
      ? <span>Aucune information.</span>
      : <span>{value}</span>
    }
  </div>;
};

const getReferenceText = getValue => {
  const notExtendedText = 'non étendu à ce jour';

  const extensionOrderDate = getValue('extensionOrderDate', notExtendedText);
  const amendmentsDate = getValue('amendmentsDate');
  const agreementDate = getValue('agreementDate');
  const extensionAmendmentsDate = getValue('extensionAmendmentsDate', notExtendedText);

  if (isNilOrEmpty(extensionOrderDate) && isNilOrEmpty(amendmentsDate) && isNilOrEmpty(agreementDate)) {
    return ' ';
  }

  const base = 'La comparaison des garanties a été réalisée selon l\'';

  if (isNilOrEmpty(amendmentsDate)) {
    return `${base}${agreementDate} ${extensionOrderDate}`;
  } else {
    let referenceText = `${base}${amendmentsDate} ${extensionAmendmentsDate}`;

    if (extensionAmendmentsDate === notExtendedText) {
      referenceText = `${referenceText}. Nous recommandons la souscription d’une formule adaptée aux garanties de cet avenant non étendu car il a vocation à être étendu et à couvrir la totalité des entreprises de la CCN.`;
    }

    return referenceText;
  }
};

const GeneralInformations = ({ rawOffer }) => {
  const getValue = (k, or = '') => R.pathOr(or, ['generalInformation', k], rawOffer);

  const highDegreeOfSolidarity = getValue('highDegreeOfSolidarity');
  return <>
    <Title banner>
      {'Analyse complémentaire de la conformité :'}
    </Title>

    <GeneralInformation
      label="Texte de référence"
      value={getReferenceText(getValue)}
    />

    <div className={classNames.generalInformation}>
      Pour information :
      <ul>
        <li>Dans le cas d’un <b>accord de branche étendu</b>, toutes les entreprises relevant de cette branche sont concernées par le respect du minimum conventionnel.</li>
        <li>Dans le cas d’un <b>accord de branche non étendu</b>, seules les entreprises adhérentes à une organisation patronale signataire de l’accord sont concernées par le respect minimum conventionnel.</li>
        <li>Dans le cas d’un <b>accord de branche applicable sous réserve de l’entrée en vigueur de l’arrêté d’extension</b>{', absence de contrainte actuelle. L\'arrêté d\'extension éventuel aura un impact sur le minimum conventionnel, et pourra nécessiter la révision du contrat si l\'entreprise souhaite être conforme.'}</li>
      </ul>
    </div>

    <GeneralInformation
      label="Participation de l'employeur"
      value={getValue('contributionDistribution')}
    />

    <GeneralInformation
      label="Maintien des garanties"
      value={getValue('maintenanceOfGuaranteesOutsidePortability')}
    />

    <GeneralInformation
      label="Degré élevé de solidarité"
      value={R.isEmpty(highDegreeOfSolidarity) ? '' : 'MMA prévoit un fonds de solidarité, mais n’étant pas l’organisme recommandé par la branche, le dispositif de Haut Degré de Solidarité décrit dans l’accord ne s’impose pas.'}
    />
  </>;
};

GeneralInformation.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string
};

GeneralInformations.propTypes = {
  rawOffer: PropTypes.object.isRequired
};

export default GeneralInformations;
