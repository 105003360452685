import PropTypes from 'prop-types';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func
]);

const queryPropTypes = {
  args: PropTypes.object,
  children: childrenPropTypes,
  mockData: PropTypes.func
};

export {
  childrenPropTypes,
  queryPropTypes
};
