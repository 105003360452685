/**
 * @flow
 * @relayHash f3e2cd05aae29175ac71687b0abee7e1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CollegeEnum = "C" | "NC" | "TOUS" | "%future added value";
export type ComparisonTypeEnum = "modular" | "pack" | "%future added value";
export type GammeEnum = "prev" | "sante" | "%future added value";
export type QComparisonsQueryVariables = {|
  idcc: string,
  gamme: GammeEnum,
  ccnRef?: ?string,
  college?: ?CollegeEnum,
|};
export type QComparisonsQueryResponse = {|
  +comparisons: ?$ReadOnlyArray<?{|
    +rawOffers: ?$ReadOnlyArray<?{|
      +rawOfferId: ?string,
      +rawOfferVersion: ?number,
      +rawOfferOptionId: ?string,
      +rawOfferOptionName: ?string,
      +selected: ?boolean,
    |}>,
    +notes: ?string,
    +comparisonType: ?ComparisonTypeEnum,
    +comparisons: ?$ReadOnlyArray<?{|
      +group: ?string,
      +refs: ?$ReadOnlyArray<?{|
        +rawOfferItemId: ?string,
        +ref: ?string,
        +label: ?string,
        +value: ?string,
        +computedValue: ?{|
          +value: ?string,
          +unit: ?string,
          +base: ?string,
        |},
        +rawOffersItem: ?$ReadOnlyArray<?{|
          +value: ?string,
          +computedValue: ?{|
            +value: ?string,
            +unit: ?string,
            +base: ?string,
          |},
          +comparison: ?{|
            +result: ?string
          |},
        |}>,
      |}>,
    |}>,
  |}>
|};
export type QComparisonsQuery = {|
  variables: QComparisonsQueryVariables,
  response: QComparisonsQueryResponse,
|};
*/


/*
query QComparisonsQuery(
  $idcc: String!
  $gamme: GammeEnum!
  $ccnRef: String
  $college: CollegeEnum
) {
  comparisons(idcc: $idcc, gamme: $gamme, ccnRef: $ccnRef, college: $college) {
    rawOffers {
      rawOfferId
      rawOfferVersion
      rawOfferOptionId
      rawOfferOptionName
      selected
    }
    notes
    comparisonType
    comparisons {
      group
      refs {
        rawOfferItemId
        ref
        label
        value
        computedValue {
          value
          unit
          base
        }
        rawOffersItem {
          value
          computedValue {
            value
            unit
            base
          }
          comparison {
            result
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ccnRef"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "college"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gamme"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "idcc"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ComputedValue",
  "kind": "LinkedField",
  "name": "computedValue",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "base",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ccnRef",
        "variableName": "ccnRef"
      },
      {
        "kind": "Variable",
        "name": "college",
        "variableName": "college"
      },
      {
        "kind": "Variable",
        "name": "gamme",
        "variableName": "gamme"
      },
      {
        "kind": "Variable",
        "name": "idcc",
        "variableName": "idcc"
      }
    ],
    "concreteType": "Comparison",
    "kind": "LinkedField",
    "name": "comparisons",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ComparisonRawOffer",
        "kind": "LinkedField",
        "name": "rawOffers",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rawOfferId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rawOfferVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rawOfferOptionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rawOfferOptionName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "selected",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "notes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "comparisonType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ComparisonGroup",
        "kind": "LinkedField",
        "name": "comparisons",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "group",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ComparisonResultRef",
            "kind": "LinkedField",
            "name": "refs",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rawOfferItemId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ref",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RawOfferItemComparison",
                "kind": "LinkedField",
                "name": "rawOffersItem",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ComparisonResult",
                    "kind": "LinkedField",
                    "name": "comparison",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "result",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QComparisonsQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "QComparisonsQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "id": "f3e2cd05aae29175ac71687b0abee7e1",
    "metadata": {},
    "name": "QComparisonsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3100f9dc72cfb0783b48c0f17ef3b95a';

module.exports = node;
