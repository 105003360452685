/**
 * @flow
 * @relayHash 87453cbba3d870e5c7fa0dc458baf7a1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CcnFromEnum = "ape" | "dsn" | "idcc" | "no_data" | "%future added value";
export type QSiretInfoQueryVariables = {|
  siret?: ?string,
  idcc?: ?string,
|};
export type QSiretInfoQueryResponse = {|
  +siretInfo: ?{|
    +name: ?string,
    +siren: ?string,
    +siret: ?string,
    +ape: ?string,
    +ccns: ?$ReadOnlyArray<?{|
      +ape: ?$ReadOnlyArray<?string>,
      +title: ?string,
      +shortTitle: ?string,
      +idcc: ?string,
      +brochure: ?string,
      +from: ?CcnFromEnum,
      +ccnRef: ?string,
    |}>,
  |}
|};
export type QSiretInfoQuery = {|
  variables: QSiretInfoQueryVariables,
  response: QSiretInfoQueryResponse,
|};
*/


/*
query QSiretInfoQuery(
  $siret: String
  $idcc: String
) {
  siretInfo(siret: $siret, idcc: $idcc) {
    name
    siren
    siret
    ape
    ccns {
      ape
      title
      shortTitle
      idcc
      brochure
      from
      ccnRef
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "idcc"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siret"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ape",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "idcc",
        "variableName": "idcc"
      },
      {
        "kind": "Variable",
        "name": "siret",
        "variableName": "siret"
      }
    ],
    "concreteType": "SiretInfo",
    "kind": "LinkedField",
    "name": "siretInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "siren",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "siret",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Ccn",
        "kind": "LinkedField",
        "name": "ccns",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "idcc",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "brochure",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "from",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ccnRef",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QSiretInfoQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QSiretInfoQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "87453cbba3d870e5c7fa0dc458baf7a1",
    "metadata": {},
    "name": "QSiretInfoQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2d53a9d9202e3a12ad9d51d3e079db0a';

module.exports = node;
