/**
 * @flow
 * @relayHash 25c96fea0fb195cde62175c53a0f4c03
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QCcnQueryVariables = {|
  ape?: ?string,
  idcc?: ?string,
  siret?: ?string,
|};
export type QCcnQueryResponse = {|
  +ccn: ?$ReadOnlyArray<?{|
    +ape: ?$ReadOnlyArray<?string>,
    +title: ?string,
    +shortTitle: ?string,
    +idcc: ?string,
    +brochure: ?string,
    +ccnRef: ?string,
  |}>
|};
export type QCcnQuery = {|
  variables: QCcnQueryVariables,
  response: QCcnQueryResponse,
|};
*/


/*
query QCcnQuery(
  $ape: String
  $idcc: String
  $siret: String
) {
  ccn(ape: $ape, idcc: $idcc, siret: $siret) {
    ape
    title
    shortTitle
    idcc
    brochure
    ccnRef
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ape"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "idcc"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siret"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ape",
        "variableName": "ape"
      },
      {
        "kind": "Variable",
        "name": "idcc",
        "variableName": "idcc"
      },
      {
        "kind": "Variable",
        "name": "siret",
        "variableName": "siret"
      }
    ],
    "concreteType": "Ccn",
    "kind": "LinkedField",
    "name": "ccn",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ape",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortTitle",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "idcc",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "brochure",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ccnRef",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QCcnQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QCcnQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "25c96fea0fb195cde62175c53a0f4c03",
    "metadata": {},
    "name": "QCcnQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8073751dd1d5ba70f412d5fd1001f114';

module.exports = node;
