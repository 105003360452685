import Tokens from '../lib/Tokens';
import assert from 'assert';
const R = require('ramda');
const { createMockEnvironment } = require('relay-test-utils');

const {
  Environment,
  Network,
  RecordSource,
  Store
} = require('relay-runtime');

const GQLEnvironment = (() => {
  let _environment = null;
  const sendRequest = async (url, headers, body, refreshToken = false) => {
    const accessToken = await Tokens.getAccessToken(refreshToken);
    const response = await fetch(url, {
      method: 'POST',
      credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin',
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`
      },
      body
    });

    if (response.status === 200) {
      const json = await response.json();
      const { errors } = json;

      if (R.isNil(errors) || R.isEmpty(errors)) return json;
      throw Error(R.pathOr('graphql request failed', [0, 'message'])(errors));
    }

    if (response.status === 401) {
      assert(!refreshToken, 'can not refresh access token');
      return sendRequest(url, headers, body, true);
    }

    return new Error('graphql request failed');
  };

  const fetchQuery = async (operation, variables) => {
    const body = JSON.stringify({
      queryId: operation.id,
      variables
    });
    const headers = {
      Accept: '*/*',
      'Content-Type': 'application/json'
    };

    return sendRequest((process.env.BACKEND_URL || '') + '/graphql', headers, body);
  };

  const init = async () => {
    if (process.env.MOCK_ENABLED === 'true') {
      _environment = createMockEnvironment();
      return;
    }

    _environment = new Environment({
      network: Network.create(fetchQuery),
      store: new Store(new RecordSource())
    });
  };

  const get = () => {
    return _environment;
  };

  return {
    init,
    get
  };
})();

export default GQLEnvironment;
