import { useState, useEffect } from 'react';
const R = require('ramda');
const { fetchQuery, useRelayEnvironment } = require('react-relay');

const useFetchQuery = ({
  defaultData = {},
  query,
  dataProp,
  args = {}
}) => {
  const [data, setData] = useState(defaultData);
  const [key, setKey] = useState(0);
  const environment = useRelayEnvironment();

  const reFetch = () => setKey(R.inc);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchQuery(environment, query, args).toPromise();
      if (R.isNil(dataProp)) {
        setData(data);
      } else {
        setData(R.prop(dataProp, data));
      }
    };
    fetchData();
  }, [key]);

  return [
    data,
    reFetch
  ];
};

export default useFetchQuery;
